import React, { useState, useEffect } from "react";
import logo from "../image/청원기연 디자인시스템-03 2.png";
import img1 from "../image/Group 572.jpeg";
import img2 from "../image/Group 574.jpeg";
import img3 from "../image/Group 573.jpeg";
import img4 from "../image/Group 27.png";
import img5 from "../image/Group 552.png";
import img6 from "../image/Group 558.png";
import img7 from "../image/Vector.png";
import img8 from "../image/Vector (1).png";
import img9 from "../image/Vector (2).png";
import img10 from "../image/Vector (3).png";
import img11 from "../image/Group 561.png";

export const Main = (props) => {
  return (
    <div className="main">
      <Intro />
      <OurProduct title={<h3>BUSINESS<br />AREA</h3>}/>
      <ContactUs />
    </div>
  );
};

export const Intro = () => {
  return (
    <div className="dashboard intro">
      <h2>CWHOIST COMPANY</h2>
      <p>
        발전된 기술을 사용하여 BUCKET HOIST CRANE, CAR LIFT ELEVATOR, 기타
        운반하역설비를 <br /> 우수한 품질로 제조 납품하는 전문 업체입니다.
      </p>
    </div>
  );
};

export const OurProduct = (props) => {
  return (
    <>
      <div className="dashboard our_product">
        {props.title}
        <div className="img_container">
          <div className="img">
            <div className="img1" onClick={props.img1}>
              <img src={img1} alt="장비이미지1" />
            </div>

            <h4>카리프트</h4>
            <p>
              지하로 Car를 직접 이동시켜 암석 및 토사를 처리하는 장비
            </p>
          </div>
          <div className="img">
            <div className="img2" onClick={props.img2}>
              <img src={img3} alt="장비이미지3" />
            </div>
            <h4>버켓호이스트</h4>
            <p>
              지하에서 발생한 암석 및 토사를 버켓에 담아 지상으로 이동시켜
              처리하는 장비
            </p>
          </div>
          <div className="img">
            <div className="img3" onClick={props.img3}>
              <img src={img2} alt="장비이미지2" />
            </div>

            <h4>기타 크레인</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export const Performances = (props) => {
  return (
    <>
      <div className="performances">
        <h3>
          최고의 기업으로 도약하고 있는 청연기원의 연혁을 한눈에 볼 수 있습니다.
        </h3>
        <img className="img1" src={img4} alt="연혁 이미지" />
        <h3>PERFORMANCES</h3>
        <img className="img2" src={img5} alt="규격" />
        <button onClick={props.onClick} className="img3">MORE ∨</button>
        {/* <img className="img3" src={img6} alt="더보기" /> */}
      </div>
    </>
  );
};

export const ContactUs = () => {
  return (
    <div className="contact_us">
      <div>
        <h3>CONTACT US</h3>
        <p>
          <img src={img7} alt="주소" />
          <span>주소</span> 서울특별시 마포구 마포대로 127 (공덕동 풍림빌딩)
          901호
        </p>
        <p>
          <img src={img8} alt="전화" />
          <span>전화번호</span> 02-718-2920
        </p>
        <p>
          <img src={img9} alt="팩스" />
          <span>팩스</span> 02-718-2922
        </p>
        <p>
          <img src={img10} alt="메일" />
          <span>이메일</span> cwhoist@hanmail.net
        </p>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.375001032895!2d126.94729788676568!3d37.546227799960995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98a05a0389af%3A0x60076d74d438f94!2z7ISc7Jq47Yq567OE7IucIOuniO2PrOq1rCDrp4jtj6zrjIDroZwgMTI3IDkwMe2YuA!5e0!3m2!1sko!2skr!4v1701408666130!5m2!1sko!2skr" width="600" height="450" style={{"border":"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      {/* <img className="map" src={img11} alt="지도" /> */}

    </div>
  );
};

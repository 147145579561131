import React, { useState, useEffect } from "react";
import { OurProduct } from "./Main";
import img1 from "../image/Rectangle 13.png";
import img2 from "../image/Rectangle 15.png";
import img3 from "../image/Rectangle 14.png";
import img4 from "../image/Rectangle 16.svg";
import img5 from "../image/Rectangle 17.svg";
import img6 from "../image/Rectangle 18.svg";

import img7 from "../image/car-15M-MIN03.svg";
import img8 from "../image/car-15M-MIN04.svg";
import img9 from "../image/car-15M-MIN01.svg";
import img10 from "../image/car-15M-MIN02.svg";
import img11 from "../image/car-30M-MIN01.svg";
import img12 from "../image/car-30M-MIN02.svg";
import img13 from "../image/car-55M-MIN01 1.svg";

import img14 from "../image/bucket5-03.svg";
import img15 from "../image/bucket15-02.svg";
import img16 from "../image/gantry02.svg";
import img17 from "../image/Rectangle 38.svg";
import img18 from "../image/Rectangle 39.svg";
import img19 from "../image/Rectangle 40.svg";

import img20 from "../image/bucket5-02.svg";
import img21 from "../image/bucket5-03.svg";
import img22 from "../image/bucket15-02.svg";
import img23 from "../image/bucket15-03.svg";
import img24 from "../image/bucket15-01.svg";
import img25 from "../image/gantry02.svg";
import img26 from "../image/gantry01.svg";
import img27 from "../image/gantry03.svg";

import img28 from "../image/tire 2.svg";

import img29 from "../image/blue_icon.svg";

export const Products = (props) => {
  return (
    <div className="wrap_container">
      {props.subMenu == "home" ? (
        <OurProduct
          img1={() => {
            props.setSubMenu("1");
            props.setHeader("header2");
          }}
          img2={() => {
            props.setSubMenu("2");
            props.setHeader("header2");
          }}
          img3={() => {
            props.setSubMenu("3");
            props.setHeader("header2");
          }}
          subMenu={props.subMenu}
          setSubMenu={props.setSubMenu}
          title={<h3>OUR PRODUCT</h3>}
          setHeader={props.setHeader}
        />
      ) : props.subMenu == "1" ? (
        <div className="dashboard performance tab">
          <TabMenu
            subMenu={props.subMenu}
            content={<CarLiftContent />}
            setSubMenu={props.setSubMenu}
            setHeader={props.setHeader}
          />
        </div>
      ) : props.subMenu == "2" ? (
        <div className="dashboard performance tab">
          <TabMenu
            subMenu={props.subMenu}
            content={<BucketHoistContent />}
            setSubMenu={props.setSubMenu}
            setHeader={props.setHeader}
          />
        </div>
      ) : props.subMenu == "3" ? (
        <div className="dashboard performance tab">
          <TabMenu
            subMenu={props.subMenu}
            content={<OtherCraneContent />}
            setSubMenu={props.setSubMenu}
            setHeader={props.setHeader}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const TabMenu = (props) => {
  return (
    <>
      <ul className="sub_nav">
        <button
          className={props.subMenu == "1" ? "btn selected" : "btn"}
          onClick={() => {
            props.setSubMenu("1");
            props.setHeader("header2");
          }}
        >
          <li>
            카리프트<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "2" ? "btn selected" : "btn"}
          onClick={() => {
            props.setSubMenu("2");
            props.setHeader("header2");
          }}
        >
          <li>
            버켓호이스트<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "3" ? "btn selected" : "btn"}
          onClick={() => {
            props.setSubMenu("3");
            props.setHeader("header2");
          }}
        >
          <li>
            기타 크레인<span></span>
          </li>
        </button>
      </ul>
      <div className="container">{props.content}</div>
    </>
  );
};

export const CarLiftContent = (props) => {
  return (
    <div className="carlift">
      <div className="section1">
        <h3>CAR LIFT ELEVATOR</h3>
        <h4>건설용 카리프트란?</h4>
        <p>
          건설용 카리프트는 건설 현장에서 사용되는 기계 설비로, 동력을 사용하여
          가이드레일을 따라 상하로 움직이는 운반구를 매달아 장비나 화물을
          운반하는 목적으로 설치됩니다.
          <br />
          건설 작업에 필요한 물품이나 인력을 효율적으로 이동시키는데 사용됩니다.
        </p>
        <h4><span>⦁</span> 카리프트의 종류</h4>

        <div className="img_container">
          <div className="img">
            <img src={img1} alt="카리프트" />
            <h4>화물용 카리프트</h4>
            <p>화물 운반만을 전용으로 하는 카리프트
            </p>
          </div>
          <div className="img">
            <img src={img3} alt="인화공용 카리프트" />
            <h4>인화공용 카리프트</h4>
            <p>화물과 인력 모두 운반할 수 있는 카리프트로 운반구 내에 인력이 타도 될 안전기준을 만족
            </p>
          </div>
          <div className="img">
            <img src={img2} alt="턴테이블 카리프트" />
            <h4>턴테이블 카리프트</h4>
            <p>턴테이블을 활용한 카리프트로 운반구의 방향을 회전시켜 입/출구의 위치를 자유롭게 정할 수 있음</p>
          </div>
        </div>
      </div>
      <div className="section2">
        <h4><span>⦁</span> 주요 구성요소</h4>
        <div className="img_container">
          <div className="img">
            <img src={img4} alt="카리프트" />
            <h4>운반구</h4>
            <p>장비나 화물을 옮기는 상하운동 운반체로 가이드 레일과 균형을 유지하며 움직이고, 출입문이 개방된 상태에서 리프트 작동이 불가</p>
          </div>
          <div className="img">
            <img src={img5} alt="버켓호이스트" />
            <h4>마스터</h4>
            <p>운반구의 상하 이동을 안내하는 가이드 레일 역할</p>
          </div>
          <div className="img">
            <img src={img6} alt="기타 크레인" />
            <h4>구동부</h4>
            <p>운반구를 움직이는 동력계통으로 전동기는 운반구의 자체하중과 적재하중을 들어올리는데 충분한 등급으로 설계</p>
          </div>
        </div>
      </div>
      <div className="section3">
        <h4><span>⦁</span> 카리프트 오더 시 필요정보</h4>
        <p>
          1. 카리프트 종류 선택 (화물용, 인화공용, 턴테이블)
          <br />
          2. 운반 속도 선택 (15m/min, 30m/min, 60m/min, 80m/min++ 등)
          <br />
          3. 수직구 사이즈 및 깊이 정보
        </p>
      </div>
      <div className="section4">
        <h4><span>⦁</span> 카리프트 이미지</h4>
        <div className="img_container">
          <div className="img">
            <img src={img7} alt="car-15M-MIN" />
            <p>car-15M-MIN</p>
          </div>
          <div className="img">
            <img src={img8} alt="car-15M-MIN" />
            <p>car-15M-MIN</p>
          </div>
          <div className="img">
            <img src={img9} alt="car-15M-MIN" />
            <p>car-15M-MIN</p>
          </div>
          <div className="img">
            <img src={img10} alt="car-15M-MIN" />
            <p>car-15M-MIN</p>
          </div>
          <div className="img">
            <img src={img11} alt="car-30M-MIN" />
            <p>car-30M-MIN</p>
          </div>
          <div className="img">
            <img src={img12} alt="car-30M-MIN" />
            <p>car-30M-MIN</p>
          </div>
          <div className="img">
            <img src={img13} alt="car-55M-MIN" />
            <p>car-55M-MIN</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BucketHoistContent = (props) => {
  return (
    <div className="bucket_hoist">
      <div className="section1">
        <h3>BUCKET HOIST CRANE</h3>
        <h4>버켓 호이스트란?</h4>
        <p>
          버켓 호이스트는 건설 현장에서 흙, 자재 또는 다른 물체를 들어올리고
          이동시키는데 사용되는 기계 설비입니다. 주로 지하공사, 토사 반출, 건설
          작업 등에서 활용됩니다.
        </p>
        <h4><span>⦁</span> 버켓 호이스트의 종류</h4>

        <div className="img_container">
          <div className="img">
            <img src={img14} alt="버켓 5루베" />
            <h4>버켓 5루베</h4>
            <p>운반구의 크기가 5루베인 버켓 호이스트</p>
          </div>
          <div className="img">
            <img src={img15} alt="버켓 15루베" />
            <h4>버켓 15루베</h4>
            <p>운반구의 크기가 15루베인 버켓 호이스트</p>
          </div>
          <div className="img">
            <img src={img16} alt="버켓 Gantry" />
            <h4>버켓 Gantry</h4>
            <p>지지하는 기둥이 움직이는 버켓 호이스트</p>
          </div>
        </div>
      </div>
      <div className="section2">
        <h4><span>⦁</span> 주요 구성요소</h4>
        <div className="img_container">
          <div className="img">
            <img src={img17} alt="와이어 드럼" />
            <h4>와이어 드럼</h4>
            <p>와이어가 권취된 드럼으로, 버켓을 들어올리고 내리는 역할</p>
          </div>
          <div className="img">
            <img src={img18} alt="와이어" />
            <h4>와이어</h4>
            <p>와이어 드럼과 연결되어 버켓을 움직임</p>
          </div>
          <div className="img">
            <img src={img19} alt="버켓" />
            <h4>버켓</h4>
            <p>흙이나 자재를 담아 들어올리는 운반구</p>
          </div>
        </div>
      </div>
      <div className="section3">
        <h4><span>⦁</span> 버켓 호이스트 오더 시 필요정보</h4>
        <p>
          1. 버켓 호이스트 종류 선택 (4루베, 15루베, 겐트리) <br />
          2. 수직구 사이즈 및 깊이 정보
        </p>
      </div>
      <div className="section4">
        <h4><span>⦁</span> 버켓 호이스트 이미지</h4>
        <div className="img_container">
          <div className="img">
            <img src={img20} alt="버켓 5루베" />
            <p>버켓 5루베</p>
          </div>
          <div className="img">
            <img src={img21} alt="버켓 5루베" />
            <p>버켓 5루베</p>
          </div>
          <div className="img">
            <img src={img22} alt="버켓 15루베" />
            <p>버켓 15루베</p>
          </div>
          <div className="img">
            <img src={img23} alt="버켓 15루베" />
            <p>버켓 15루베</p>
          </div>
          <div className="img">
            <img src={img24} alt="버켓 15루베" />
            <p>버켓 15루베</p>
          </div>
          <div className="img">
            <img src={img25} alt="버켓 Gantry" />
            <p>버켓 Gantry</p>
          </div>
          <div className="img">
            <img src={img26} alt="버켓 Gantry" />
            <p>버켓 Gantry</p>
          </div>
          <div className="img">
            <img src={img27} alt="버켓 Gantry" />
            <p>버켓 Gantry</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OtherCraneContent = (props) => {
  return (
    <div className="other_crane">
      <div className="section1">
        <h3>OTHER CRANE</h3>
        <h4>기타 크레인</h4>
        <p>타이어 호이스트는 건설 현장이나 항만에서 사용되는 기계 설비입니다. 이는 흙, 자재 또는 다른 물체를 들어올리고 이동시키는데 활용됩니다.</p>
        <h4><span>⦁</span> 기타 크레인 이미지</h4>

        <div className="img_container">
          <div className="img">
            <img src={img28} alt="타이어 호스트" />
            <h4>타이어 호스트</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

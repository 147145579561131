import React, { useState, useEffect } from "react";
import { OurProduct } from "./Main";
import img1 from "../image/Group 522.png";
import img2 from "../image/Group 528.png";

export const Performance = (props) => {
  return (
    <div className="wrap_container">
       <ul className="sub_nav">
        <button
          className={props.subMenu == "1" ? "btn selected" : "btn"}
          onClick={() => props.setSubMenu("1")}
        >
          <li>
            카리프트<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "2" ? "btn selected" : "btn"}
          onClick={() => props.setSubMenu("2")}
        >
          <li>
            버켓호이스트<span></span>
          </li>
        </button>
      </ul>

      {props.subMenu == "1" ? (
              <div className="dashboard performance performance1">
             <h3>CAR LIFT ELEVATOR</h3>
             <img className="img1" src={img1} alt="CAR LIFT ELEVATOR" />
             </div>
      ) : props.subMenu == "2" ? (
        <div className="dashboard performance performance1">
             <h3>BUCKET HOIST CRANE</h3>
             <img className="img2" src={img2} alt="BUCKET HOIST CRANE" />
             </div>
      )  : (
        <></>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { OurProduct } from "./Main";
import img1 from "../image/technologicalCapability.svg";
import img2 from "../image/Group 762.png";
import img3 from "../image/technologicalCapability2.svg";

import img4 from "../image/인증1.svg";
import img4_1 from "../image/인증1_1.svg";
import img5 from "../image/인증2.svg";
import img5_1 from "../image/인증2_1.svg";
import img6 from "../image/인증3.svg";
import img6_1 from "../image/인증3_1.svg";
import img7 from "../image/인증4.svg";
import img7_1 from "../image/인증4_1.svg";
import img8 from "../image/인증5.svg";
import img8_1 from "../image/인증5_1.svg";

import img9 from "../image/인증6.svg";
import img9_1 from "../image/인증6_1.svg";
import img10 from "../image/인증7.svg";
import img10_1 from "../image/인증7_1.svg";
import img11 from "../image/인증8.svg";
import img11_1 from "../image/인증8_1.svg";
import img12 from "../image/인증9.svg";
import img12_1 from "../image/인증9_1.svg";
import img13 from "../image/인증10.svg";
import img13_1 from "../image/인증10_1.svg";

export const TechnologicalCapability = (props) => {
  const [showPopUp, setShowPopUp] = useState(null);

  useEffect(() => {
    if (showPopUp != null) {
      document.body.style.overflowY = "hidden";
      return;
    } else {
      document.body.style.overflowY = "auto";
      return;
    }
  }, [showPopUp]);

  return (
    <div className="wrap_container">
      <ul className="sub_nav">
        {/* <button
          className={props.subMenu == "1" ? "btn selected" : "btn"}
          onClick={() => props.setSubMenu("1")}
        >
          <li>
            개발기술<span></span>
          </li>
        </button> */}
        <button
          className={props.subMenu == "2" ? "btn selected" : "btn"}
          onClick={() => props.setSubMenu("2")}
        >
          <li>
            인증내역<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "3" ? "btn selected" : "btn"}
          onClick={() => props.setSubMenu("3")}
        >
          <li>
            특허기술<span></span>
          </li>
        </button>
      </ul>

      {props.subMenu == "1" ? (
        <div className="dashboard performance technologicalCapability">
          <div>
            <h3>개발기술</h3>
            <p>
              안전은 건설현장에서 가장 중요한 가치로 여겨져왔으며
              ‘중대재해기업처벌법’이 시행됨에 따라 건설사들은 더욱더 안전사고
              예방에 총력을 기울이고 있습니다. <br /> 이러한 사회적 니즈를 충족시키기
              위해 ‘건설용 카리프트 안전 모니터링 서비스 및 자동제어 시스템’을
              개발합니다.
            </p>
            <img className="img1" src={img1} alt="개발기술1" />
          </div>
          <img className="img2" src={img2} alt="개발기술2" />
          <img className="img3" src={img3} alt="개발기술3" />
        </div>
      ) : props.subMenu == "2" ? (
        <div className="dashboard performance technologicalCapability technologicalCapability2">
          <div>
            <h3>인증내역</h3>
            <p>
              청원기연은 생산 물품에 대한 최고의 품질을 보장하기 위해 경영혁신,
              기술혁신을 지향하고 품질 및 환경 관리 시스템을 구축하여 <br />{" "}
              생산품들을 효율적이고 체계적으로 생산/제조하고 있습니다.
            </p>
            <div className="img_container">
              <div
                className="img"
                onClick={() =>
                  setShowPopUp(<img src={img4_1} alt="ISO 9001" />)
                }
              >
                <img src={img4} alt="ISO 9001" />
                <p>ISO 9001</p>
              </div>
              <div
                className="img"
                onClick={() =>
                  setShowPopUp(<img src={img5_1} alt="ISO 9001" />)
                }
              >
                <img src={img5} alt="ISO 14001" />
                <p>ISO 14001</p>
              </div>
              <div
                className="img"
                onClick={() =>
                  setShowPopUp(<img src={img6_1} alt="ISO 9001" />)
                }
              >
                <img src={img6} alt="메인비즈" />
                <p>메인비즈</p>
              </div>
              <div
                className="img"
                onClick={() =>
                  setShowPopUp(<img src={img7_1} alt="ISO 9001" />)
                }
              >
                <img src={img7} alt="이노비즈" />
                <p>이노비즈</p>
              </div>
              <div
                className="img"
                onClick={() =>
                  setShowPopUp(<img src={img8_1} alt="ISO 9001" />)
                }
              >
                <img src={img8} alt="연구개발전담부서" />
                <p>연구개발전담부서</p>
              </div>
            </div>
          </div>
        </div>
      ) : props.subMenu == "3" ? (
        <div className="dashboard performance technologicalCapability technologicalCapability2">
          <div>
            <h3>특허기술</h3>
            <p>
              버켓 호이스트, 카리프트, 건설 안전 등에 관한 다양한 특허를
              보유하고 있으며 뛰어난 기술력을 바탕으로 고객의 만족을
              실천하겠습니다.
            </p>
            <div className="img_container">
              <div
                className="img"
                onClick={() => setShowPopUp(<img src={img9_1} alt="호이스트 장치 및 이를 구비한 소형 호이스트 크레인" />)}
              >
                <img src={img9} alt="호이스트 장치 및 이를 구비한 소형 호이스트 크레인" />
                <p>호이스트 장치 및 이를 구비한<br /> 소형 호이스트 크레인</p>
              </div>
              <div className="img" onClick={() => setShowPopUp(<img src={img10_1} alt="카리프트" />)}>
                <img src={img10} alt="카리프트" />
                <p>카리프트</p>
              </div>
              <div className="img" onClick={() => setShowPopUp(<img src={img11_1} alt="호이스트 장치 및 이를 구비한 버킷 호이스트 크레인" />)}>
                <img src={img11} alt="호이스트 장치 및 이를 구비한 버킷 호이스트 크레인" />
                <p>호이스트 장치 및 이를 구비한<br /> 버킷 호이스트 크레인</p>
              </div>
              <div className="img" onClick={() => setShowPopUp(<img src={img12_1} alt="다수의 모터를 구비한 카리프트" />)}>
                <img src={img12} alt="다수의 모터를 구비한 카리프트" />
                <p>다수의 모터를 구비한 카리프트</p>
              </div>
              <div className="img" onClick={() => setShowPopUp(<img src={img13_1} alt="카리프트 안전운전 시스템" />)}>
                <img src={img13} alt="카리프트 안전운전 시스템" />
                <p>카리프트 안전운전 시스템</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {showPopUp != null ? (
        <div
          className="popup"
          onClick={(e) => {
            e.stopPropagation();
            setShowPopUp(null);
          }}
        >
          {showPopUp}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

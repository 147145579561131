import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./image/청원기연 디자인시스템-03 2.png";
import logo2 from "./image/청원기연 로고 글자포함 1.svg";
import language from "./image/Vector.svg";
import { AboutUs } from "./views/AboutUs";
import { Products } from "./views/Products";
import { Performance } from "./views/Performance";
import { TechnologicalCapability } from "./views/TechnologicalCapability";
const { Main } = require("./views/Main");

function App() {
  const [menu, setMenu] = useState("home");
  const [header, setHeader] = useState("header1");
  const [key, setKey] = useState("");
  const [kor, setKor] = useState("kor");
  const [subMenu, setSubMenu] = useState("1");

  // 같은 라인 메뉴 통일

  return (
    <div className="app">
      <div
        className={
          header == "header1" ? "header header1" : "header header2"}
      >
        <button onClick={() => { setHeader("header1"); setMenu("home"); setKey(Math.random()); }} className="logo">
          <img src={header == "header1" ? logo : header == "header2" ? logo2 : logo} alt="청원기연 로고" />
        </button>
        <ul className="nav">
          <button>
            <li onClick={() => { setSubMenu("1");setHeader("header2"); setMenu("about"); setKey(Math.random());}}>ABOUT US</li>
            <ul>
              <li onClick={() => {  setMenu("about");setSubMenu("1"); setHeader("header2"); }}>인사말</li>
              <li onClick={() => {  setMenu("about");setSubMenu("2"); setHeader("header1"); }}>회사연혁</li>
              <li onClick={() => {  setMenu("about");setSubMenu("3"); setHeader("header2"); }}>회사개요</li>
              <li onClick={() => {  setMenu("about");setSubMenu("4"); setHeader("header2"); }}>조직도</li>
              <li onClick={() => {  setMenu("about");setSubMenu("5"); setHeader("header2"); }}>오시는 길</li>
            </ul>
          </button>
          <button>
            <li onClick={() => { setSubMenu("home"); setHeader("header1"); setMenu("products"); setKey(Math.random());}}>PRODUCTS</li>
            <ul>
              <li onClick={() => { setMenu("products");setSubMenu("1"); setHeader("header2"); }}>카리프트</li>
              <li onClick={() => { setMenu("products");setSubMenu("2"); setHeader("header2"); }}>버켓호이스트</li>
              <li onClick={() => { setMenu("products");setSubMenu("3"); setHeader("header2"); }}>기타 크레인</li>
            </ul>
          </button>
          <button>
            <li onClick={() => { setSubMenu("1"); setHeader("header2"); setMenu("performances"); setKey(Math.random()); }}>PERFORMANCES</li>
            <ul>
              <li  onClick={() => { setMenu("performances");setSubMenu("1"); setHeader("header2"); }}>카리프트</li>
              <li  onClick={() => { setMenu("performances");setSubMenu("2"); setHeader("header2"); }}>버켓호이스트</li>
            </ul>
          </button>
          <button>
            <li onClick={() => { setSubMenu("2");setHeader("header2"); setMenu("technologicalCapability"); setKey(Math.random()); }}>TECHNOLOGICAL CAPABILITY</li>
            <ul>
              {/* <li  onClick={() => { setMenu("technologicalCapability");setSubMenu("1"); setHeader("header2"); }}>개발기술</li> */}
              <li  onClick={() => { setMenu("technologicalCapability");setSubMenu("2"); setHeader("header2"); }}>인증내역</li>
              <li  onClick={() => { setMenu("technologicalCapability");setSubMenu("3"); setHeader("header2"); }}>특허기술</li>
            </ul>
          </button>
        </ul>
      </div>
      {menu == "home" ? <Main onClick={() => setMenu("performances")} /> :
        menu == "about" ? <AboutUs key={key} setHeader={setHeader} subMenu={subMenu} setSubMenu={setSubMenu} /> :
          menu == "products" ? <Products key={key} setHeader={setHeader} subMenu={subMenu} setSubMenu={setSubMenu} /> :
            menu == "performances" ? <Performance key={key} subMenu={subMenu} setSubMenu={setSubMenu} /> :
              menu == "technologicalCapability" ? <TechnologicalCapability key={key} subMenu={subMenu} setSubMenu={setSubMenu} /> :
                <></>}
      {menu == "home" ? (
        <div className="footer">
          <img src={header == "header1" ? logo : logo2} alt="청원기연 로고" />
          <div>
            <p>
              <span>(주)청원기연</span>&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
              <span>대표자</span> 권순흥&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
              <span>TEL</span> 02-718-2920&nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;
              <span>FAX</span> 02-718-2922 &nbsp;&nbsp;&nbsp; |
              &nbsp;&nbsp;&nbsp;
              <span>EMAIL</span> cwhoist@hanmail.net
            </p>
            <p>
              <span>본사</span> 서울특별시 마포구 마포대로 127 (공덕동 풍림빌딩)
              901호
            </p>
            <p>
              <span>제1공장</span>경기도 화성시 장안면 수정로 101 (독정리
              599-15)&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
              <span>제2공장</span>경기도 화성시 장안면 장안로 366 (독정리
              708-2)
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;

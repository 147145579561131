import React, { useState, useEffect } from "react";
import logo from "../image/청원기연 로고.png";
import img1 from "../image/Component 1.png";
import img2 from "../image/Group 532.png";
import img3 from "../image/캡처 1.png";
import img4 from "../image/Group 761.svg";
import img5 from "../image/캡처 3.png";
import img6 from "../image/Group 556.png";
import img7 from "../image/Vector.png";
import img8 from "../image/Vector (1).png";
import img9 from "../image/Vector (2).png";
import img10 from "../image/Vector (3).png";
import img11 from "../image/Group 561.png";

export const AboutUs = (props) => {
  return (
    <div className="wrap_container about" style={props.subMenu == "2" ? {"backgroundColor": "#121417", "height" : "100vh"}:{"backgroundColor": "#fff"} }>
      <ul className={props.subMenu == "2" ? "sub_nav sub_nav2" : "sub_nav"}>
        <button
          className={props.subMenu == "1" ? "btn selected" : "btn"}
          onClick={() => {props.setSubMenu("1");props.setHeader("header2")}}
        >
          <li>
            인삿말<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "2" ? "btn selected" : "btn"}
          onClick={() => {props.setSubMenu("2");props.setHeader("header1")}}
        >
          <li>
            회사연혁<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "3" ? "btn selected" : "btn"}
          onClick={() =>  {props.setSubMenu("3");props.setHeader("header2")}}
        >
          <li>
            회사개요<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "4" ? "btn selected" : "btn"}
          onClick={() =>  {props.setSubMenu("4");props.setHeader("header2")}}
        >
          <li>
            조직도<span></span>
          </li>
        </button>
        <button
          className={props.subMenu == "5" ? "btn selected" : "btn"}
          onClick={() =>  {props.setSubMenu("5");props.setHeader("header2")}}
        >
          <li>
            오시는 길<span></span>
          </li>
        </button>
      </ul>
      {props.subMenu == "1" ? (
        <Intro />
      ) : props.subMenu == "2" ? (
        <Performances />
      ) : props.subMenu == "3" ? (
        <Overview />
      ) : props.subMenu == "4" ? (
        <Organization />
      ) : props.subMenu == "5" ? (
        <Location />
      ) : (
        <></>
      )}
    </div>
  );
};

export const Intro = () => {
  return (
    <div className="dashboard intro">
      <img className="logo" src={logo} alt="로고" />
      <div className="word_wrap">
        <h3>
          (주)청원기연
          <br /> 홈페이지를 방문해 주셔서 감사합니다.
        </h3>
        <h4>안녕하십니까.</h4>
        <p>
          저희 ㈜청원기연은 1998년 설립되어 BUCKET HOIST CRANE, CAR LIFT
          ELEVATOR, 기타 운반하역설비를 꾸준히 제조 납품하여 왔으며 앞으로도
          더욱 발전된 기술을 사용하여 우수한 제품을 생산하도록 노력하겠습니다.
          아울러 고객에게 인정받는 최고의 기업이 되도록 최선을 다하겠습니다.
          감사합니다.
        </p>
        <b>(주)청원기연 대표이사 · 임직원 일동</b>
      </div>
    </div>
  );
};

export const Performances = () => {
  const [long, setLong] = useState(false);
  return (
    <>
      <div className="dashboard performances">
        <h3>
          최고의 기업으로 도약하고 있는<br /> 청연기원의 연혁을 한눈에 볼 수 있습니다.
        </h3>
        <img className={ long == false ? "img1" : "img1 img1_long"} src={img4} alt="연혁 이미지" />
        <button className="img2" onClick={() => setLong(!long)}>{long == true ? "FOLD ∧" : "MORE ∨"}</button>
      </div>
    </>
  );
};

export const Overview = () => {
  return (
    <>
      <div className="dashboard overview">
        <h3>OVERVIEW</h3>
        <img className="img1" src={img1} alt="overview" />
      </div>
    </>
  );
};
export const Organization = () => {
  return (
    <>
      <div className="dashboard organization">
        <h3>ORGANIZATION</h3>
        <img className="img2" src={img2} alt="organization 이미지" />
      </div>
    </>
  );
};
export const Location = () => {
  return (
    <>
      <div className="dashboard location">
        <h3>LOCATION</h3>
        <div className="box">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.375001032895!2d126.94729788676568!3d37.546227799960995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98a05a0389af%3A0x60076d74d438f94!2z7ISc7Jq47Yq567OE7IucIOuniO2PrOq1rCDrp4jtj6zrjIDroZwgMTI3IDkwMe2YuA!5e0!3m2!1sko!2skr!4v1701408666130!5m2!1sko!2skr"
              width="913.16"
              height="300"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contact_us">
            <h4>
              청원기연
              <br />
              본사
            </h4>
            <div>
              <p>
                <img src={img7} alt="주소" />
                <span>주소</span> 서울특별시 마포구 마포대로 127 (공덕동
                풍림빌딩) 901호
              </p>
              <p>
                <img src={img8} alt="전화" />
                <span>전화번호</span> 02-718-2920
              </p>
              <p>
                <img src={img9} alt="팩스" />
                <span>팩스</span> 02-718-2922
              </p>
              <p>
                <img src={img10} alt="메일" />
                <span>이메일</span> cwhoist@hanmail.net
              </p>
            </div>
          </div>
        </div>
        <div className="box_container">
          <div className="box2">
          <h4>제 1공장</h4>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.2115206335266!2d126.85340557651261!3d37.07626877216967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b10da6839f1d1%3A0x1f9a044199653574!2z6rK96riw64-EIO2ZlOyEseyLnCDsnqXslYjrqbQg7IiY7KCV66GcIDEwMSA1OTkgMTU!5e0!3m2!1sko!2skr!4v1701408886816!5m2!1sko!2skr"
                width="445.83"
                height="300"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact_us">
              <div>
                <p>
                  <img src={img7} alt="주소" />
                  <span>주소</span>경기도 화성시 장안면 수정로 101 (독정리
                  599-15)
                </p>
                <p>
                  <img src={img8} alt="전화" />
                  <span>전화번호</span> 031-358-2921
                </p>
                <p>
                  <img src={img9} alt="팩스" />
                  <span>팩스</span> 031-358-2922
                </p>
              </div>
            </div>
          </div>
          <div className="box3">
          <h4>제 2공장</h4>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.2115206335266!2d126.85340557651261!3d37.07626877216967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b10da6839f1d1%3A0x1f9a044199653574!2z6rK96riw64-EIO2ZlOyEseyLnCDsnqXslYjrqbQg7IiY7KCV66GcIDEwMSA1OTkgMTU!5e0!3m2!1sko!2skr!4v1701408971896!5m2!1sko!2skr"
                width="445.83"
                height="300"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact_us">
              <div>
                <p>
                  <img src={img7} alt="주소" />
                  <span>주소</span>경기도 화성시 장안면 장안로 366 (독정리
                  708-2)
                </p>
                <p>
                  <img src={img8} alt="전화" />
                  <span>전화번호</span> 031-358-2921
                </p>
                <p>
                  <img src={img9} alt="팩스" />
                  <span>팩스</span> 031-358-2922
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
